import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./partnersIcons.module.css";

const PartnersIcons = ({ block }) => {

  const [opened, setOpened] = React.useState(false);

  const { wp: { acfOptionsPartners: { partnersIcons: { partnersIconsBlock } } } } = useStaticQuery(graphql`
      query MyOptionsPartnersQuery {
        wp {
          acfOptionsPartners {
            partnersIcons {
              partnersIconsBlock {
                title
                icons {
                  link {
                    url
                    title
                    target
                  }
                  icon {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(quality: 100)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `);

  return (
    <>
      {/* <section className={`${s.partners} ${opened ? s.opened : ''}`}> */}
      <section className={`${s.partners} `}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              {!!partnersIconsBlock?.title && <h2 className={s.title}>{parse(partnersIconsBlock.title)}</h2>}
            </div>
            <div className="col-xl-11 mx-auto">
              <div className={s.partnersItems}>
                {partnersIconsBlock?.icons?.length > 0 && partnersIconsBlock?.icons?.map((item, i) => {
                  return <PartnersItem item={item} key={i} />
                })}
              </div>
            </div>
          </div>
        </div>
        {/* <div className={s.showMore}>
          <button type="button" onClick={() => { setOpened(true) }}>Show More</button>
        </div> */}
      </section>
    </>
  );
};

export default PartnersIcons;

const PartnersItem = ({ item }) => {
  const icon = {
    data: item.icon?.localFile?.childImageSharp?.gatsbyImageData,
    alt: item.icon?.altText || ``,
  }
  if (!icon?.data) {
    return <></>;
  }
  if (!!item?.link?.url) {
    return <Link to={item?.link?.url} className={s.partnersItem}>
      <GatsbyImage
        placeholder="none"
        loading="eager"
        image={icon.data}
        alt={icon.alt}
        className="w-auto" />
    </Link>
  }
  return <span className={s.partnersItem}>
      <GatsbyImage
        placeholder="none"
        loading="eager"
        image={icon.data}
        alt={icon.alt}
        className="w-auto" />
    </span>
}

